<template>
  <DefaultTemplate class="home">
    <iframe width="500" height="500" src="/leaflet?lat=-6.1753924&long=106.8272&z=16" frameborder="0"></iframe>
  </DefaultTemplate>
</template>

<script>
// @ is an alias to /src
import DefaultTemplate from '@/templates/default.vue'

export default {
  name: 'Home',
  components: {
    DefaultTemplate
  }
}
</script>
