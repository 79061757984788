import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import VueLayers from 'vuelayers'
import 'vuelayers/lib/style.css'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import Vue2TouchEvents from 'vue2-touch-events'


Vue.config.productionTip = false
Vue.use(VueLayers, {
  dataProjection: 'EPSG:4326',
})
Vue.use(Vue2TouchEvents);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
