<template lang="">
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/leaflet">Leaflet</router-link>
  </div>
</template>
<script>
export default {
  name: "NavMenu"
}
</script>
<style lang="">
  
</style>