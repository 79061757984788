<template>
  <div class="container">
    <nav-menu></nav-menu>
    <slot></slot>
  </div>
</template>
<script>
  import NavMenu from "@/components/NavMenu"
  export default {
    name: 'DefaultTemplate',
    components: {
      NavMenu
    },
    data () {
      return {
      }
    },
  }
</script>